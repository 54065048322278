import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faExpand } from "@fortawesome/free-solid-svg-icons";

const SearchBox = ({
  searchTerm,
  setSearchTerm,
  setFilteredSongs,
  songs,
  isMaximized,
  onMaximize,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  const onClear = () => {
    setSearchTerm("");
  };

  const cleanString = (str) => {
    return str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
  };

  // Search functionality
  useEffect(() => {
    if (searchTerm) {
      const cleanSearch = cleanString(searchTerm),
        isNewSongSearch = cleanSearch === "new",
        results = songs.filter((song) => {
          const artist = song.ARTIST || null,
            title = song.TITLE || null;

          if (artist && title) {
            return (
              cleanString(`${artist}`).includes(cleanSearch) ||
              cleanString(`${title}`).includes(cleanSearch) ||
              (isNewSongSearch && song.IS_NEW)
            );
          }
          return false;
        });
      setFilteredSongs(results);
    } else {
      setFilteredSongs(songs);
    }
  }, [setFilteredSongs, searchTerm, songs]);

  return (
    <div className="search-container">
      <input
        className="search-field"
        type="text"
        placeholder="What song will you sing?"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="search-clear">
        <FontAwesomeIcon
          className="icon"
          icon={faTimes}
          size="xs"
          onClick={onClear}
        />
      </div>
      <div
        className={`maximize-app ${
          !isTabletOrMobile && !isMaximized ? "hide" : ""
        }`}
      >
        <FontAwesomeIcon
          className="icon"
          icon={faExpand}
          size="xs"
          onClick={onMaximize}
        />
      </div>
    </div>
  );
};

export default SearchBox;
