// App.js
import React, { useState, useEffect } from "react";
import "./App.scss";
import NavBar from "./components/NavBar";
import Section from "./components/Section";
import SongsTable from "./components/songstable/SongsTable";
import Event from "./components/Event";
import { loadJSON } from "./utils/Utils";

function App() {
  const [eventData, setEventData] = useState(null);
  const [eventOver, setEventOver] = useState(false);
  const [eventIsFriday, setEventIsFriday] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const appVersionRaw = process.env.REACT_APP_VERSION || "";
  const appVersion =
    process.env.NODE_ENV === "production"
      ? `${process.env.REACT_APP_VERSION}/`
      : "";

  console.log(appVersionRaw + " " + process.env.NODE_ENV);

  useEffect(() => {
    loadJSON(`events/next_event.json?v=${appVersionRaw}`, (eventData) => {
      const currentDate = new Date(),
        eventDate = new Date(eventData.date);

      setEventData(eventData);
      if (currentDate.getTime() > eventDate.getTime() + 36 * 60 * 60 * 1000) {
        setEventOver(true);
      }

      if (eventDate.getDay() === 5) {
        setEventIsFriday(true);
      }

      setIsLoading(false);
    });
  }, [appVersionRaw, setEventData, setEventOver]);

  return (
    <div className="App">
      {!isLoading ? (
        <>
          <NavBar appVersion={appVersion} />
          <div className="content" id="page-wrap">
            <div className="section-holder">
              <Section id="next-event" title="Next Event">
                <Event
                  appVersion={appVersion}
                  eventDate={eventData.dateAsString}
                  eventLink={eventData.fb_link}
                  eventIsSpecial={eventData.special}
                  eventIsBattlevox={eventData.battlevox}
                  specialLink={eventData.special_link}
                  eventIsFriday={eventIsFriday}
                  eventOver={eventOver}
                />
              </Section>
              <Section id="song-list" title="Song List">
                <SongsTable appVersion={appVersion} />
              </Section>
              <div className="footer">
                <p className="copyright">
                  &copy; {new Date().getFullYear()} Darklight Promotions. All
                  rights reserved.
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default App;
